import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.loadedClientConfig && (
                    <NavMenu />
                )}                
                {!this.props.loadedClientConfig && (
                    <LoadingIndicatorComponent />
                )}
                <br />
                <Container>                    
                    {this.props.children}
                </Container>
                <br />                
            </div>
        );
    }
}

export const LoadingIndicatorComponent = () => {

    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
            <div className="dl">
                <img className="dl__square" src="/images/joyhub_auth_image.png" style={{ cursor: 'pointer' }} onClick={() => { window.location.reload(); }} />
            </div>
        </div>
    );
};