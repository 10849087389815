import React, { Component, useEffect, useState } from 'react';
import { Collapse, Container, NavbarBrand, NavbarToggler, NavItem, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, ButtonDropdown, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        
        this.state = {
            collapsed: true,
            notifications: [],
            notifsCount: 0,
            chatNotifsCount: 0,
            moreNotifs: true
        };
    }

    toggleNavbar = (e) => {      
        this.setState({
            collapsed: !this.state.collapsed
        });
        e.preventDefault();
    }    

    render() {
        return (
            <header>
                <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark border-bottom box-shadow mb-3 fixed-top shadow">
                    <Container>
                        <NavbarBrand onClick={() => { window.location.href = '/'; }}>
                            {/*<img src={sessionStorage.getItem("client_navbar_logo")} style={{ height: '48px' }} className="d-inline-block align-top nbmbl" />*/}
                        </NavbarBrand>

                        {/*<div className="d-block d-sm-none">*/}
                        {/*    <ul className="navbar-nav d-lg-none ml-auto navbar-icons d-flex flex-row navbarMobileIconsList">                                                                */}
                        {/*        <NavItem>*/}
                        {/*            <NavLink className="nav-link navLink " activeClassName="navLinkActive" to="/chat/home">*/}
                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.619 21.671c-5.038 1.227-8.711-1.861-8.711-5.167 0-3.175 3.11-5.467 6.546-5.467 3.457 0 6.546 2.309 6.546 5.467 0 1.12-.403 2.22-1.117 3.073-.029 1 .558 2.435 1.088 3.479-1.419-.257-3.438-.824-4.352-1.385zm-10.711-5.167c0-4.117 3.834-7.467 8.546-7.467.886 0 1.74.119 2.544.338-.021-4.834-4.761-8.319-9.998-8.319-5.281 0-10 3.527-10 8.352 0 1.71.615 3.391 1.705 4.695.047 1.527-.851 3.718-1.661 5.313 2.168-.391 5.252-1.258 6.649-2.115.803.196 1.576.304 2.328.363-.067-.379-.113-.765-.113-1.16z" /></svg>*/}
                        {/*                {this.state.chatNotifsCount != 0 && (*/}
                        {/*                    <span style={{ marginLeft: '5px' }} class="badge badge-secondary" style={{ backgroundColor: "#3D9294" }}>{this.state.chatNotifsCount}</span>*/}
                        {/*                )}*/}
                        {/*            </NavLink>*/}
                        {/*        </NavItem>*/}
                        {/*        <NavItem>*/}
                        {/*            <NavLink className="nav-link navLink " activeClassName="navLinkActive" to="/archive/root">*/}
                        {/*                <BsArchive />*/}
                        {/*            </NavLink>*/}
                        {/*        </NavItem>*/}
                        {/*        <NavItem>*/}
                        {/*            <NavLink className="nav-link navLink" activeClassName="navLinkActive" to="/notif">*/}
                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" /></svg>*/}
                        {/*                {this.state.notifsCount != 0 && (*/}
                        {/*                    <span style={{ marginLeft: '5px' }} class="badge badge-secondary" style={{ backgroundColor: "#3D9294" }}>{this.state.notifsCount}</span>*/}
                        {/*                )}*/}
                        {/*            </NavLink>*/}
                        {/*        </NavItem>*/}
                        {/*        <NavbarToggler onClick={this.toggleNavbar} className="mr-2 no-outline" />*/}
                        {/*    </ul>*/}
                        {/*</div>                        */}

                        {/*<Collapse isOpen={!this.state.collapsed} navbar className="bg-dark navbarCollapse navbarCollapse123">*/}
                        {/*    <div className="d-sm-none">*/}
                        {/*        <ul className="navbar-nav mr-auto truenav">*/}
                        {/*            <li className="nav-item">*/}
                        {/*                <NavLink className="nav-link initialN" to="/chat/home" activeClassName="navLinkActive">Chat</NavLink>*/}
                        {/*            </li>*/}
                        {/*            <li className="nav-item">*/}
                        {/*                <NavLink className="nav-link initialN" to="/groups" activeClassName="navLinkActive">Grupuri</NavLink>*/}
                        {/*            </li>                                    */}
                        {/*            <li className="nav-item">*/}
                        {/*                <NavLink className="nav-link initialN" to={"/profile/" + sessionStorage.getItem("current_user_id")}>Profilul meu</NavLink>*/}
                        {/*            </li>*/}
                        {/*            <li className="nav-item">*/}
                        {/*                <NavLink className="nav-link initialN" to="/editProfile">Setari cont</NavLink>*/}
                        {/*            </li>*/}
                        {/*            <li className="nav-item">*/}
                        {/*                <a className="nav-link initialN" href="#">Ajutor</a>*/}
                        {/*            </li>*/}
                        {/*            <li className="nav-item">*/}
                        {/*                <a className="nav-link initialN" onClick={() => { authService.signOut(); }}>Delogare</a>*/}
                        {/*            </li>*/}
                        {/*        </ul>                                */}
                        {/*    </div>                            */}
                        {/*    <div className="d-none d-sm-block navbar-collapse ">*/}
                        {/*        <SearchBox/>*/}
                        {/*        <ul className="navbar-nav my-2 my-lg-0">*/}
                        {/*            <NavItem>*/}
                        {/*                <NavLink exact className="nav-link navLink" activeClassName="navLinkActive" to="/">*/}
                        {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z" /></svg>*/}
                        {/*                </NavLink>*/}
                        {/*            </NavItem>*/}
                        {/*            <NavItem>*/}
                        {/*                <NavLink className="nav-link navLink" activeClassName="navLinkActive" to="/chat/home">*/}
                        {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.619 21.671c-5.038 1.227-8.711-1.861-8.711-5.167 0-3.175 3.11-5.467 6.546-5.467 3.457 0 6.546 2.309 6.546 5.467 0 1.12-.403 2.22-1.117 3.073-.029 1 .558 2.435 1.088 3.479-1.419-.257-3.438-.824-4.352-1.385zm-10.711-5.167c0-4.117 3.834-7.467 8.546-7.467.886 0 1.74.119 2.544.338-.021-4.834-4.761-8.319-9.998-8.319-5.281 0-10 3.527-10 8.352 0 1.71.615 3.391 1.705 4.695.047 1.527-.851 3.718-1.661 5.313 2.168-.391 5.252-1.258 6.649-2.115.803.196 1.576.304 2.328.363-.067-.379-.113-.765-.113-1.16z" /></svg>*/}
                        {/*                    {this.state.chatNotifsCount != 0 && (*/}
                        {/*                        <span style={{ marginLeft: '5px' }} class="badge badge-secondary" style={{ backgroundColor: "#3D9294" }}>{this.state.chatNotifsCount}</span>*/}
                        {/*                    )}*/}
                        {/*                </NavLink>*/}
                        {/*            </NavItem>*/}
                        {/*            <NavItem>*/}
                        {/*                <NavLink className="nav-link navLink" activeClassName="navLinkActive" to="/groups">*/}
                        {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z" /></svg>*/}
                        {/*                </NavLink>*/}
                        {/*            </NavItem>*/}
                        {/*            <UncontrolledDropdown nav inNavbar>*/}
                        {/*                <DropdownToggle*/}
                        {/*                    onClick={() => {*/}
                        {/*                        post("/Social/UserManager/MarkNotificationsAsRead", {}, (response) => {*/}
                        {/*                            if (response !== true) {*/}
                        {/*                                alert("Eroare la actualizarea notificarilor");*/}
                        {/*                            }*/}
                        {/*                        });*/}
                        {/*                    }}*/}
                        {/*                    className="nav-link navLink nobkg no-outline" style={{ backgroundColor: 'transparent!important', border: 'none' }}>*/}
                        {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" /></svg>*/}
                        {/*                    {this.state.notifsCount != 0 && (*/}
                        {/*                        <span style={{ marginLeft: '5px' }} class="badge badge-secondary themed_notif_badge">{this.state.notifsCount}</span>*/}
                        {/*                    )}*/}
                        {/*                </DropdownToggle>*/}
                        {/*                <DropdownMenu right className="asds">*/}
                        {/*                    {this.state.notifications.map((notif, index) =>*/}
                        {/*                        <DropdownItem key={index} onClick={() => { window.location.href = notif.redirect_to }}*/}
                        {/*                            className={notif.is_read ? "list-group-item" : "list-group-item font-weight-bold"}*/}
                        {/*                            style={{paddingTop: '5px', paddingBottom: '0px'}}*/}
                        {/*                        >*/}
                        {/*                            <small className="float-right">{notif.created_at}</small>*/}
                        {/*                            <br/>*/}
                        {/*                            <p style={{ padding: '0'}}>{notif.text_content}</p>*/}
                        {/*                        </DropdownItem>*/}
                        {/*                    )}*/}
                        {/*                    {this.state.moreNotifs && (*/}
                        {/*                        <DropdownItem tag={Link} to={"/notif"} className="asds" onClick={() => {*/}
                        {/*                            const notifs123 = [...this.state.notifications];*/}
                        {/*                            for (var x = 0; x < this.state.notifications.length; x++)*/}
                        {/*                                notifs123[x].is_read = true;*/}
                        {/*                            this.setState({ notifications: notifs123, notifsCount: 0 });*/}
                        {/*                        }}>*/}
                        {/*                            Mai multe notificari*/}
                        {/*                        </DropdownItem>*/}
                        {/*                    )}*/}
                        {/*                </DropdownMenu>*/}
                        {/*            </UncontrolledDropdown>                                    */}

                        {/*            {((sessionStorage.getItem("current_user_role") == "GodAdmin" || sessionStorage.getItem("current_user_role") == "PlatformAdmin") && (*/}
                        {/*                <NavItem>*/}
                        {/*                    <NavLink className="nav-link navLink" activeClassName="navLinkActive" to="/admin">*/}
                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.269 19.743l-11.945-11.945c-.557-.557-.842-1.33-.783-2.115.115-1.485-.395-3.009-1.529-4.146-1.03-1.028-2.376-1.537-3.723-1.537-.507 0-1.015.072-1.505.216l3.17 3.17c.344 1.589-1.959 3.918-3.567 3.567l-3.169-3.17c-.145.492-.218 1-.218 1.509 0 1.347.51 2.691 1.538 3.721 1.135 1.136 2.66 1.645 4.146 1.53.783-.06 1.557.226 2.113.783l11.946 11.944c.468.468 1.102.73 1.763.73 1.368 0 2.494-1.108 2.494-2.494 0-.638-.244-1.276-.731-1.763zm-1.769 2.757c-.553 0-1-.448-1-1s.447-1 1-1c.553 0 1 .448 1 1s-.447 1-1 1zm-7.935-15.289l5.327-5.318c.584-.585 1.348-.878 2.113-.878.764 0 1.529.292 2.113.878.589.587.882 1.357.882 2.125 0 .764-.291 1.528-.873 2.11l-5.326 5.318-4.236-4.235zm-3.53 9.18l-5.227 5.185c-.227.23-.423.488-.574.774l-.301.58-2.1 1.07-.833-.834 1.025-2.146.58-.302c.286-.15.561-.329.79-.558l5.227-5.185 1.413 1.416z" /></svg>*/}
                        {/*                    </NavLink>*/}
                        {/*                </NavItem>*/}
                        {/*            ))}                                    */}

                        {/*            <LoginMenu />*/}
                        {/*        </ul>                    */}
                        {/*    </div>*/}
                        {/*    </Collapse>*/}
                    </Container>
                </nav>
            </header>
        );
    }
}