import React, { Component, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Layout, LoadingIndicatorComponent } from './components/Layout';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './styles.scss';
import authService from './components/api-authorization/AuthorizeService';
import { Link } from 'react-router-dom';
import {FaNotesMedical} from 'react-icons/fa';

function getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [key, value] = el.split('=');
        cookie[key.trim()] = value;
    })
    return cookie[cookieName];
}
function setCrossSubdomainCookie(name, value, days) {
    const assign = name + "=" + escape(value) + ";";
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString() + ";";
    const path = "path=/;";
    const domain = document.domain == 'localhost' ? 'domain=localhost' : "domain=" + (document.domain.match(/[^\.]*\.[^.]*$/)[0]) + ";";
    document.cookie = assign + expires + path + domain;
}
var delete_cookie = function (name) {
    if (name == "_joy_hub_oidc_token") {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.aquannect.com;';
    }
    else if (name == "_joy_hub_auth_return_url") {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.aquannect.com;';
    }
    else {
        document.cookie = name + ';max-age=0';
    }    
};

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout loadedClientConfig={true}>
                <AuthorizeRoute exact path='/' component={RouterComponent} />
                <AuthorizeRoute exact path='/home' component={RouterComponent} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}

const RouterComponent = () => {

    const [token, setToken] = useState(undefined);

    useEffect(() => {
        authService.getAccessToken().then(async (token) => {
            if (token == null || token == undefined)
                return;

            const response = await fetch("validate_identity_token", {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            if (response.status == 401) {
                authService.signOut({ returnUrl: '\\' });
            }
            else {
                console.log(`Bearer ${token}`);
                setToken(`Bearer ${token}`);
                try {
                    delete_cookie("_joy_hub_oidc_token");
                    setCrossSubdomainCookie("_joy_hub_oidc_token", token, 30);

                    if (window.location.pathname == '/' || window.location.pathname == '/home') {
                        const returnUrl = getCookie('_joy_hub_auth_return_url');
                        delete_cookie("_joy_hub_auth_return_url");
                        if (returnUrl != undefined && returnUrl != null) {
                            window.location = decodeURIComponent(returnUrl);
                        }
                    }
                }
                catch {
                    console.log("Could not set shared cookie.");
                }
            }
        });
    }, []);

    return (
        <div className="container text-center">
            <p>Module Aquannect WIP</p>
            {token === undefined && (<LoadingIndicatorComponent/>)}
            {token !== undefined && (
                <>
                    <a class="btn btn-primary"
                        style={{padding: '10px', paddingLeft: '20px'}}
                        href="https://ssm.aquannect.com"
                    >
                        Dosar medical
                        <FaNotesMedical size={24} style={{margin: '10px'}}/>
                    </a>
                    <br />
                    <br />
                    <button onClick={() => {
                        delete_cookie("_joy_hub_oidc_token");
                        authService.signOut({ returnUrl: '\\' });
                    }} className="btn btn-primary">
                        Sign out
                    </button>  
                </>
            )}
        </div>
    )
}